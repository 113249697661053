import React from 'react';
import styled from 'styled-components';

import AppProvider from '../AppProvider';

import Hero from '../Hero';
import Welcome from '../Welcome';
import About from '../About';
import Benefits from '../Benefits';
import Profile from '../Profile';
import Registry from '../Registry';
import Footer from '../Footer';

import Container from '../Container';
import Navbar from '../Navbar';

function App() {
  return (
    <AppProvider>
      <Section theme="black">
        <Container>
          <Navbar />
          <Hero />
        </Container>
      </Section>

      <Section>
        <Container>
          <Welcome />
        </Container>
      </Section>
      <Section theme="black">
        <Container>
          <About />
        </Container>
      </Section>
      <Section>
        <Container>
          <Benefits />
        </Container>
      </Section>
      <Section theme="black">
        <Container>
          <Profile />
        </Container>
      </Section>
      <Section id="register-form">
        <Container>
          <Registry />
        </Container>
      </Section>
      <Footer />
    </AppProvider>
  );
}

const Section = styled.section`
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: ${(props) =>
    props.theme === 'black' ? 'var(--black)' : 'var(--white)'};
  color: ${(props) =>
    props.theme === 'black' ? 'var(--white)' : 'var(--black)'};

  @media screen and (max-width: 1850px) {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @media screen and (max-width: 992px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export default App;
