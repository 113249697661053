import React from 'react';
import styled from 'styled-components';
import Form from '../Form';

function Registry() {
  return (
    <Wrapper>
      <Form />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export default Registry;
