import React from 'react';
import styled from 'styled-components';
import Heading from '../Heading';
import Img from '../Img';

const mixer = new URL('mixer.png', import.meta.url);
const chat = new URL('chat.png', import.meta.url);

function About() {
  return (
    <Wrapper>
      <Heading>О нас</Heading>
      <Row>
        <Text>
          Golova.io&nbsp;&mdash; это софт для прокатчиков, где сотни
          зарегистрированных компаний управляют своим бизнесом и&nbsp;ведут
          проекты. С&nbsp;запуском проекта job.golova.io они могут находить
          сотрудников и&nbsp;назначать исполнителей прямо из&nbsp;проекта, прямо
          в&nbsp;интерфейсе основной системы.
        </Text>
        <Inset sz={76}>
          <Img src={mixer} />
        </Inset>
      </Row>
      <Row direction="reverse">
        <Text>
          Компания на&nbsp;своей стороне формирует заявку на&nbsp;поиск
          сотрудника (от&nbsp;грузчика и&nbsp;водителя до&nbsp;техника,
          звукорежиссера и&nbsp;т.д.). Вы&nbsp;в&nbsp;личном кабинете job
          получаете приглашение и&nbsp;откликаетесь на&nbsp;него.
          По&nbsp;окончанию проекта заказчик и&nbsp;исполнитель оценивают
          взаимодействие, формируя таким образом рейтинг.
        </Text>
        <Img src={chat} />
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 60px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 40px;
  }

  @media screen and (max-width: 576px) {
    gap: 20px;
  }
`;

const Inset = styled.div`
  margin-top: -${(props) => props.sz}px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 60px;
  flex-direction: ${(props) =>
    props.direction === 'reverse' ? 'row-reverse' : 'row'};

  @media screen and (max-width: 992px) {
    gap: 40px;
  }

  @media screen and (max-width: 768px) {
    display: block;
    & img {
      display: none;
    }
  }

  @media screen and (max-width: 576px) {
    gap: 20px;
  }
`;

const Text = styled.p`
  max-width: 50%;
  font-size: 27px;
  line-height: 30px;

  @media screen and (max-width: 992px) {
    font-size: 22px;
    line-height: 26px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }

  @media screen and (max-width: 576px) {
    font-size: 19px;
    line-height: 24px;
  }
`;

export default About;
