import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as styles from './Form.module.css';
import Button from '../Button';

const userAgreement = new URL('User_Agreement_Golova.pdf', import.meta.url);

function Modal() {
  const [error, setError] = React.useState(null);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Необходимо ввести ФИО'),
    email: Yup.string()
      .required('Необходимо ввести email')
      .email('Введите корректный email')
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Введите корректный email'),
    password: Yup.string()
      .min(8, 'Пароль должен быть не меньше 8 символов')
      .required('Необходимо ввести пароль'),
      password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
      .required('Необходимо ввести подтверждение пароля'),
    acceptTerms: Yup.bool().oneOf(
      [true],
      'Необходимо принять условия пользовательского соглашения'
    ),
  });

  function getCookie(name) {
    if (!document.cookie) {
      return null;
    }
  
    const xsrfCookies = document.cookie.split(';')
      .map(c => c.trim())
      .filter(c => c.startsWith(name + '='));
  
    if (xsrfCookies.length === 0) {
      return null;
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
  }

  const csrfToken = getCookie('XSRF-TOKEN');

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);

    const { errors } = formState;
  
    function onSubmit(data) {
      const newUser = {
        name: data.name,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        language: 'ru',
      };
    
    fetch('/ajax_register', {
      method: 'POST',
      body: JSON.stringify(newUser),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-XSRF-TOKEN': csrfToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (
          res?.status === 'error' &&
          Array.isArray(res?.error) &&
          res?.error?.length !== 0
        ) {
          setError(res.error);
        }
        reset();
        document.location.href="/home";
      })
      .catch((err) => {
        console.log(err.message);;
      });

    setError([
      'Email incorrect',
      'password did not match',
      'user name not found',
    ]);
  }

  return (
    <div className={styles.modal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles['modal-title']}>Создайте учетную запись</div>
        <div className={styles['form-row']}>
          <label htmlFor="language">Язык</label>
          <input
            value="ru"
            onChange={(e) => setLanguage(e.target.value)}
            type="text"
            name="language"
            id="language"
            disabled
          />
        </div>
        <div className={styles['form-row']}>
          <label htmlFor="name">ФИО</label>
          <input
            {...register('name')}
            type="text"
            name="name"
            id="name"
          />
          {errors.name?.message && (
            <span className={styles.error}>{errors.name?.message}</span>
          )}
        </div>
        <div className={styles['form-row']}>
          <label htmlFor="email">Email</label>
          <input
            {...register('email')}
            type="email"
            name="email"
            id="email"
          />
          {errors.email?.message && (
            <span className={styles.error}>{errors.email?.message}</span>
          )}
        </div>
        <div className={styles['form-row']}>
          <label htmlFor="password">Пароль</label>
          <input
            {...register('password')}
            type="password"
            name="password"
            id="password"
          />
          {errors.password?.message && (
            <span className={styles.error}>{errors.password?.message}</span>
          )}
        </div>
        <div className={styles['form-row']}>
          <label htmlFor="password_confirmation">Подтвердите пароль</label>
          <input
            {...register('password_confirmation')}
            type="text"
            name="password_confirmation"
            id="password_confirmation"
          />
          {errors.password_confirmation?.message && (
            <span className={styles.error}>
              {errors.password_confirmation?.message}
            </span>
          )}
        </div>
        <div className={styles['form-checkbox']}>
          <input
            type="checkbox"
            id="accept-terms"
            name="acceptTerms"
            defaultChecked
            {...register('acceptTerms')}
          />
          <label htmlFor="accept-terms">
            Принять условия пользовательского
          </label>
          <a
            download={userAgreement}
            href="https://lite.golova.io/golova_soglashenie.pdf"
            target="_blank"
          >
            соглашения
          </a>
          {errors.acceptTerms?.message && (
            <span className={styles.error}>{errors.acceptTerms?.message}</span>
          )}
        </div>
        <div className={styles['form-link']}>
          <a
            href="/login"
            style={{ color: 'inherit' }}
          >
            Уже зарегистрированы?
          </a>
        </div>
        {error &&
          error?.length !== 0 &&
          error.map((err, i) => {
            <div key={i}>{err}</div>;
          })}
        <div className={styles['form-btns']}>
          <Button
            onClick={() => reset()}
            className={styles['form-btn']}
            type="button"
          >
            Отмена
          </Button>
          <Button
            className={styles['form-btn']}
            type="submit"
          >
            Зарегистрироваться
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Modal;
