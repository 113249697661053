import React from 'react';
import styled from 'styled-components';
import { Player } from '@lottiefiles/react-lottie-player';

function Hero() {
  return (
    <Wrapper>
      <Player
        autoplay
        loop
        src="https://lottie.host/70d05f5f-4799-4295-98e9-b9ccfb0c9839/ViiKx2dgTw.json"
        style={{ height: '100%', width: '100%' }}
      ></Player>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 5vw;
`;

export default Hero;
