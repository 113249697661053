import React from 'react';
import styled from 'styled-components';
import Heading from '../Heading';
import Img from '../Img';

const profile = new URL('profile.png', import.meta.url);

function Profile() {
  return (
    <Wrapper>
      <Heading>Заполните максимально полно анкету:</Heading>
      <Row>
        <ImgWrapper>
          <Img src={profile} />
        </ImgWrapper>
        <List>
          <Item>укажите свой опыт работы,</Item>
          <Item>специальности, которыми вы&nbsp;владеете,</Item>
          <Item>навыки, которыми обладаете,</Item>
          <Item>оборудование, на&nbsp;котором работаете</Item>
        </List>
      </Row>
      <Text>Все это поможет вам найти идеального заказчика.</Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 40px;
  }

  @media screen and (max-width: 576px) {
    gap: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
  align-items: center;
  gap: 20px;
  flex-direction: ${(props) =>
    props.direction === 'reverse' ? 'row-reverse' : 'row'};

  @media screen and (max-width: 992px) {
    gap: 20px;
  }

  @media screen and (max-width: 768px) {
    display: block;
    & img {
      display: none;
    }
  }

  @media screen and (max-width: 576px) {
    gap: 20px;
  }
`;

const ImgWrapper = styled.div`
  flex: 1;
`;

const List = styled.ul`
  max-width: 50%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 992px) {
    gap: 15px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

const Item = styled.li`
  font-size: 34px;
  color: var(--white);
  line-height: 1.2;

  /* font-size: 27px;
  line-height: 30px; */

  @media screen and (max-width: 1200px) {
    font-size: 27px;
  }

  @media screen and (max-width: 992px) {
    font-size: 22px;
    line-height: 26px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }

  @media screen and (max-width: 576px) {
    font-size: 19px;
    line-height: 24px;
  }
`;

const Text = styled.p`
  /* max-width: 50%; */
  font-size: 27px;
  line-height: 30px;

  @media screen and (max-width: 992px) {
    font-size: 22px;
    line-height: 26px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }

  @media screen and (max-width: 576px) {
    font-size: 19px;
    line-height: 24px;
  }
`;

export default Profile;
