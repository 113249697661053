.modal {
  background: #fff;
  overflow-y: auto;
  width: 600px;
  max-width: calc(100% - 30px);
  padding: 15px 20px;
/* max-height: calc(100vh - 30px); */
  border: 1px solid var(--black);
}

.modal-title {
  margin-bottom: 15px;
  font-size: 28px;
  line-height: 32px;
  color: #000;
  padding-right: 20px;
}

.form-row {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.form-row label {
  display: block;
  width: 140px;
  color: #aeaeae;
  font-size: 18px;
  line-height: 20px;
}

.form-row input {
  display: block;
  width: calc(100% - 155px);
  border: 2px solid #040404;
  border-radius: 8px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
  color: #000;
  font-size: 18px;
}

.form-link {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
}

.form-checkbox {
  position: relative;
  margin-bottom: 15px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}

.form-checkbox a {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  color: inherit;
}

.form-checkbox input {
  position: absolute;
  top: 50%;
  left: -100000px;
}

.form-checkbox label {
  display: block;
  position: relative;
  cursor: pointer;
  color: #000000;
  font-size: 16px;
  line-height: 18px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 40px;
  margin-right: 5px;
}

.form-checkbox label::before {
  position: absolute;
  display: block;
  content: '';
  box-sizing: border-box;
  border: 2px solid #000000;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
  z-index: 1;
}

.form-checkbox label::after {
  position: absolute;
  display: block;
  content: '';
  height: 10px;
  width: 18px;
  transform: rotate(-45deg);
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  z-index: 2;
  top: 8px;
  left: 8px;
  opacity: 0;
}

.form-checkbox input:checked + label:before {
  background: #000000;
}

.form-checkbox input:checked + label:after {
  opacity: 1;
}

.form-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.error {
  font-size: 12px;
  line-height: 14px;
  color: #f01b1b;
  margin: 5px 0 0 auto;
}

.form-btn {
  margin-left: 15px;
}

@media screen and (max-width: 768px) {
  .form-row {
    margin-bottom: 15px;
  }

  .form-row label {
    width: 100%;
    margin-bottom: 4px;
  }

  .form-row input {
    width: 100%;
    height: 44px;
  }

  .form-btn {
    order: 1;
    margin-left: 0;
    width: 100%;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 500px) {
  .error {
    margin: 5px auto 0 0;
  }
}
