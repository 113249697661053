import React from 'react';
import styled from 'styled-components';

function Img({ src, alt = '', width = '100%', ...delegated }) {
  return (
    <div>
      <StyledImg
        src={src}
        alt={alt}
        width={width}
        {...delegated}
      />
    </div>
  );
}

const StyledImg = styled.img`
  width: ${(props) => props.img};
`;

export default Img;
