.navbar {
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  transition: all 0.9s ease-out;
}

.navbar-desktop {
  display: flex;
  align-items: center;
  gap: 15px;
}

.navbar-mobile {
  display: none;
  margin-left: 24px;
  /* margin-top: 10px; */
  transition: all 0.9s ease-out;
}

@media screen and (max-width: 992px) {
  .navbar .navbar-desktop {
    display: none;
  }

  .navbar .navbar-mobile {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    transition: all 0.9s ease-out;
  }
}

.navbar-mobile.collapse {
  display: none;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: 24px;
  border-bottom: 2px solid var(--black);
  cursor: pointer;
  outline-offset: 4px;
  text-decoration: none;
}

.link:hover {
  border-bottom: 2px solid var(--white);
  transition: 0.3s ease-out;
}

.navbar-toggler {
  display: none;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  border-color: #fff;
  cursor: pointer;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

@media screen and (max-width: 992px) {
  .navbar-toggler {
    display: block;
  }
}

.icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}
