import React from 'react';
import styled from 'styled-components';

const logo = new URL('logo.svg', import.meta.url);

function Logo() {
  return (
    <StyledLink href="/">
      <Img src={logo} />
    </StyledLink>
  );
}

const StyledLink = styled.a``;

const Img = styled.img`
  width: 170px;
  vertical-align: middle;
  border-style: none;
`;

export default Logo;
