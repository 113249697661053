import React from 'react';
import styled from 'styled-components';

function Heading({ level = 2, ...delegated }) {
  if (level === 1) {
    return <MainHeading {...delegated} />;
  }

  return <StyledHeading {...delegated} />;
}

const MainHeading = styled.h1`
  font-family: 'Pragmata Pro Mono';
  font-size: 70px;
  line-height: 79px;
  font-weight: 400;
  letter-spacing: -0.5px;

  @media screen and (max-width: 1850px) {
    font-size: 46px;
    line-height: 54px;
  }

  @media screen and (max-width: 992px) {
    text-align: center;
  }

  @media screen and (mx-width: 576px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

const StyledHeading = styled.h2`
  font-size: 47px;
  line-height: 53px;
  font-weight: 400;
  letter-spacing: -0.5px;

  @media screen and (max-width: 768px) {
    font-size: 44px;
  }

  @media screen and (max-width: 576px) {
    font-size: 40px;
    line-height: 44px;
  }
`;

export default Heading;
