import React from 'react';
import styled from 'styled-components';
import Heading from '../Heading';
import Img from '../Img';

const icon1 = new URL('icon1.png', import.meta.url);
const icon2 = new URL('icon2.png', import.meta.url);
const icon3 = new URL('icon3.png', import.meta.url);

function Benefits() {
  return (
    <Wrapper>
      <Heading>Преимущества для вас:</Heading>
      <Row>
        <Benefit>
          <ImgWrapper>
            <Img
              src={icon1}
              width={74}
            />
          </ImgWrapper>
          <Text>
            Вы&nbsp;получаете доступ к&nbsp;профессиональному сообществу
            потенциальных заказчиков и&nbsp;работодателей.
          </Text>
        </Benefit>
        <Benefit>
          <ImgWrapper>
            <Img
              src={icon2}
              width={74}
            />
          </ImgWrapper>
          <Text>
            Вы&nbsp;сможете получать больше заказов, планировать свое рабочее
            время, работать над своей репутацией, поднимать за&nbsp;счет этого
            стоимость своих услуг, искать новых заказчиков.
          </Text>
        </Benefit>
        <Benefit>
          <ImgWrapper>
            <Img
              src={icon3}
              width={74}
            />
          </ImgWrapper>
          <Text>
            Снижается риск негативного взаимодействия с&nbsp;заказчиком, т.к.
            это влияет на&nbsp;вашу и&nbsp;его репутацию.
          </Text>
        </Benefit>
      </Row>
      <Heading>Преимущества для компаний:</Heading>
      <Row>
        <Benefit>
          <ImgWrapper>
            <Img
              src={icon1}
              width={74}
            />
          </ImgWrapper>
          <Text>
            Вы&nbsp;получаете доступ к&nbsp;базе специалистов прямо
            из&nbsp;интерфейса своего аккаунта.
          </Text>
        </Benefit>
        <Benefit>
          <ImgWrapper>
            <Img
              src={icon2}
              width={74}
            />
          </ImgWrapper>
          <Text>
            Вы&nbsp;видите рейтинг потенциального исполнителя и&nbsp;отзывы
            о&nbsp;его работе от&nbsp;других компаний.
          </Text>
        </Benefit>
        <Benefit>
          <ImgWrapper>
            <Img
              src={icon3}
              width={74}
            />
          </ImgWrapper>
          <Text>
            Вы&nbsp;можете найти исполнителя для проекта в&nbsp;другом городе
            и&nbsp;даже в&nbsp;другой стране
          </Text>
        </Benefit>
      </Row>
    </Wrapper>
  );
}

const ImgWrapper = styled.div`
  @media screen and (max-width: 768px) {
    flex-shrink: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 44px;

  @media screen and (max-width: 992px) {
    gap: 40px;
  }

  @media screen and (max-width: 576px) {
    gap: 30px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  flex-direction: ${(props) =>
    props.direction === 'reverse' ? 'row-reverse' : 'row'};

  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 40px;
  } ;
`;

const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;

  @media screen and (max-width: 992px) {
    flex-direction: row;
    gap: 40px;
  }

  @media screen and (max-width: 400px) {
    gap: 0;
    & img {
      display: none;
    }
  }
`;

const Text = styled.p`
  max-width: 520px;
  font-size: 24px;
  line-height: 1.2;
`;

export default Benefits;
