import React from 'react';
import Logo from '../Logo';
import * as styles from './Navbar.module.css';

const burger = new URL('burger.svg', import.meta.url);

function Navbar() {
  const [show, setShow] = React.useState(false);
  const collapse = show ? '' : styles.collapse;

  function toggle() {
    setShow((show) => !show);
  }

  return (
    <nav className={styles.navbar}>
      <Logo />
      <button
        className={styles['navbar-toggler']}
        onClick={toggle}
      >
        <span className={styles.icon}>
          <svg
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke="#fff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              d="M4 7h22M4 15h22M4 23h22"
            />
          </svg>
        </span>
      </button>
      <div className={styles['navbar-desktop']}>
        <a
          className={styles.link}
          href="/login"
        >
          Личный кабинет
        </a>
        <a
          className={styles.link}
          href="/register"
        >
          Регистрация
        </a>
      </div>
      <div className={`${styles['navbar-mobile']} ${collapse}`}>
        <a
          className={styles.link}
          href="login"
        >
          Личный кабинет
        </a>
        <a
          className={styles.link}
          href="register"
        >
          Регистрация
        </a>
      </div>
    </nav>
  );
}

export default Navbar;
