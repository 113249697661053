import React from 'react';
import styled from 'styled-components';

function Button({ className = '', ...delegated }) {
  return (
    <StyledButton
      className={className}
      {...delegated}
    />
  );
}

const StyledButton = styled.button`
  min-width: 180px;
  border-radius: 8px;
  padding: 19px 20px 21px 20px;
  font-size: 18px;
  line-height: 21px;

  border: none;
  background: #000000;
  color: white;
  cursor: pointer;
  outline-offset: 4px;
  transition: all 0.3s ease-out;

  &:hover {
    opacity: 0.8;
  }
`;

export default Button;
