import React from 'react';

import useKeydown from '../../hooks/use-keydown';

export const AppContext = React.createContext();

function AppProvider({ children }) {
  const [toasts, setToasts] = React.useState([]);

  return (
    <AppContext.Provider value={{ toasts }}>{children}</AppContext.Provider>
  );
}

export default AppProvider;
