import React from 'react';
import styled from 'styled-components';

import Heading from '../Heading';
import Img from '../Img';
import Button from '../Button';

const welcome = new URL('welcome.png', import.meta.url);

function Welcome() {
  return (
    <Wrapper>
      <Article>
        <Heading level={1}>
          Добро пожаловать в&nbsp;сервис поиска вакансий job.golova.io!
        </Heading>
        <Description>
          Это место, где профессионалы из&nbsp;индустрии проката и&nbsp;ивентов
          могут найти работу в&nbsp;соответствии со&nbsp;своими навыками.
        </Description>
        <ButtonLink href="#register-form">Попробовать</ButtonLink>
      </Article>
      <Img src={welcome} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 40px;
  }

  @media screen and (max-width: 576px) {
    gap: 20px;
  }
`;

const Article = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 60px;

  @media screen and (max-width: 992px) {
    gap: 40px;
    text-align: center;
  }

  @media screen and (max-width: 576px) {
    gap: 20px;
  }
`;

const Description = styled.p`
  max-width: 520px;
  font-size: 27px;
  line-height: 30px;

  @media screen and (max-width: 992px) {
    font-size: 22px;
    line-height: 26px;
  }

  @media screen and (max-width: 576px) {
    font-size: 19px;
    line-height: 24px;
  }
`;

function ButtonLink({ className = '', ...delegated }) {
  return (
    <StyledLink
      className={className}
      {...delegated}
    />
  );
}

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  border-radius: 8px;
  padding: 19px 20px 21px 20px;
  font-size: 18px;
  line-height: 21px;

  border: none;
  background: #000000;
  color: white;
  cursor: pointer;
  outline-offset: 4px;
  transition: all 0.3s ease-out;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
`;

export default Welcome;
