import React from 'react';
import styled from 'styled-components';

function Container({ theme = 'white', ...delegated }) {
  if (theme === 'black') {
    return <WrapperBlack {...delegated} />;
  }

  return <Wrapper {...delegated} />;
}

const Wrapper = styled.div`
  width: 1770px;
  margin: 0 auto;
  /* padding: 40px; */
  position: relative;

  /* border-radius: 15px; */
  /* background-color: var(--white); */
  /* color: var(--black); */

  @media screen and (max-width: 1850px) {
    width: 1170px;
  }

  @media screen and (max-width: 1200px) {
    width: 970px;
  }

  @media screen and (max-width: 992px) {
    width: 750px;
    /* padding: 30px; */
  }

  @media screen and (max-width: 768px) {
    width: 540px;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
    /* padding: 15px; */
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const WrapperBlack = styled(Wrapper)`
  background-color: var(--black);
  color: var(--white);
`;

export default Container;
