import React from 'react';
import styled from 'styled-components';
import Img from '../Img';
import Container from '../Container';

const facebook = new URL('facebook.svg', import.meta.url);
const instagram = new URL('instagram.svg', import.meta.url);
const youtube = new URL('youtube.svg', import.meta.url);
const dzen = new URL('dzen.svg', import.meta.url);
const vk = new URL('vk.svg', import.meta.url);

function Footer() {
  return (
    <footer>
      <Container>
        <Wrapper>
          <Contacts>
            <Link href="tel:+74959466670">+7 (495) 946-6670</Link>
            <div>
              <Link href="https://golova.io">golova.io</Link>
              {', '}
              <span style={{ fontSize: '20px' }}>2010-2024</span>
            </div>
          </Contacts>
          <Links>
            <Link href="https://golova.io/info/view/">информация</Link>
            <Link href="https://golova.io/question/form/">обратная связь</Link>
          </Links>
          <Social>
            <Link href="https://www.facebook.com/golova.io">
              <Img
                src={facebook}
                width={52}
              />
            </Link>
            <Link href="https://www.instagram.com/golova_io/">
              <Img
                src={instagram}
                width={52}
              />
            </Link>
            <Link href="https://youtube.com/c/GolovaTeam">
              <Img
                src={youtube}
                width={52}
              />
            </Link>
            <Link href="https://dzen.ru/golovaio">
              <Img
                src={dzen}
                width={52}
              />
            </Link>
            <Link href="https://vk.com/golovaio">
              <Img
                src={vk}
                width={52}
              />
            </Link>
          </Social>
        </Wrapper>
      </Container>
    </footer>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;

  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
    gap: 15px;
  }
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
`;

const Social = styled.div`
  display: flex;
  gap: 12px;

  & a {
    transition: 0.3s ease-out;
  }

  & a:hover {
    opacity: 0.5;
    transition: 0.3s ease-out;
  }
`;

const Links = styled.div`
  display: flex;
  gap: 20px;

  a {
    border-bottom: 2px solid transparent;
    transition: 0.3s ease-out;
  }

  & a:hover {
    border-bottom: 2px solid black;
    transition: 0.3s ease-out;
  }
`;

const Link = styled.a`
  font-size: 20px;
  color: inherit;
  text-decoration: none;
`;

export default Footer;
